<div class="row m-auto buycheckout" *ngIf="loadingFlag; else loading">
  <div class="col-md-8">
    <div class="section-container">
      <div  class="action-error" *ngIf="showBinLookupErr">
        <i class="material-icons notification-icon link-anchor">notifications</i
        ><strong>{{'errors.binLookErr' | cxTranslate}}</strong>
      </div>
      <div  class="action-error" *ngIf="showSavedCardError">
        <i class="material-icons notification-icon link-anchor">notifications</i
        ><strong>{{'errors.savedCardErr' | cxTranslate}}</strong>
      </div>
      <div  class="action-error" *ngIf="showUnexpectedErr">
        <i class="material-icons notification-icon link-anchor">notifications</i
        ><strong>{{'errors.creditCardPaymentUnexpectedErr' | cxTranslate}}</strong>
      </div>
      <div  class="action-error" *ngIf="showCardNotAllowedErr">
        <i class="material-icons notification-icon link-anchor">notifications</i
        ><strong>{{'errors.cardNotAllowedErr' | cxTranslate}}</strong>
      </div>
    </div>
    
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isAccountCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isAccountCollapsed = !isAccountCollapsed"
          [attr.aria-expanded]="!isAccountCollapsed"
          aria-controls="accountDetails"
        >
          {{ 'titles.customerAccount' | cxTranslate }}
        </div>
        <hr />
        <app-customer-account
          id="accountDetails"
          [ngbCollapse]="isAccountCollapsed"
          [soldAddress]="this.soldToAddress"
          [customerAccount]="this.customerAccount"
        ></app-customer-account>
      </div>
    </div>

    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isPaymentCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isPaymentCollapsed = !isPaymentCollapsed"
          [attr.aria-expanded]="!isPaymentCollapsed"
          aria-controls="paymentDetails"
        >
          {{ 'titles.payment' | cxTranslate }}
        </div>
        <hr />
        <app-payment-details
          id="paymentDetails"
          [ngbCollapse]="isPaymentCollapsed"
          [paymentTerm]="this.paymentTerm"
          (setPaymentData)="getPaymentInput($event)"
          (sendPayInfoData)="getPaymentInfo($event)"
          [showCreditCard]="this.showCreditCard"
          [hidePurchaseOrder]="this.hidePurchaseOrder"
          [ccPaymentInfo]="this.ccPaymentInfo"
          [savedPayCards]="this.savedCards"
        ></app-payment-details>
      </div>
    </div>
    <div class="section-container" *ngIf="isPayerDisabled">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isPayerCollapsed ? 'accordion-toggle' : 'accordion-toggle-collapsed'
          "
          (click)="isPayerCollapsed = !isPayerCollapsed"
          [attr.aria-expanded]="!isPayerCollapsed"
          aria-controls="payerDetails"
        >
          {{ 'titles.payer' | cxTranslate }}
        </div>
        <hr />
        <app-payer-details
          id="payerDetails"
          [ngbCollapse]="isPayerCollapsed"
          [payerAddress]="this.payerAddress"
          [inkoTerm]="this.inkoTerm"
          [largestFilmLeadtime]="this.largestFilmLeadtime"
          [largestNonFilmLeadtime]="this.largestNonFilmLeadtime"
          [collectType]="this.collectList"
          [cartType]="this.cartType"
          [prePayAddType]="this.prePayAddList"
          [payerCheckoutData]="this.payerCheckoutData"
          (setModelData)="getPayerInput($event)"
          (setReqDate)="getReqDate($event)"
          (checkPayerAddressVal)="checkPayerAddressVal($event)"
        >
        </app-payer-details>
      </div>
    </div>
    <!-- <div class="section-container">
      <div class="section-container-buy">
        <div [ngClass]="isbilltoaddressCollapsed ? 'accordion-toggle' : 'accordion-toggle-collapsed'"
          (click)="isbilltoaddressCollapsed = !isbilltoaddressCollapsed"
          [attr.aria-expanded]="!isbilltoaddressCollapsed" aria-controls="payerDetails">
          {{"titles.billtoaddress" | cxTranslate}}
        </div>
        <hr />
        <app-billto-address id="payerDetails" [ngbCollapse]="isbilltoaddressCollapsed"
          [sippingAddress]="this.shippingAddress" [inkoTerm]="this.inkoTerm"
          [largestFilmLeadtime]="this.largestFilmLeadtime" [largestNonFilmLeadtime]="this.largestNonFilmLeadtime"
          [collectType]="this.collectList" [cartType]="this.cartType" [prePayAddType]="this.prePayAddList"
          [checkoutData]="this.checkoutData" (setModelData)="getShippingInput($event)" (setReqDate)="getReqDate($event)"
          (checkShippingAddressVal)="checkShippingAddressVal($event)">
        </app-billto-address>
      </div>
    </div> -->
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isShippingCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isShippingCollapsed = !isShippingCollapsed"
          [attr.aria-expanded]="!isShippingCollapsed"
          aria-controls="shippingDetails"
        >
          {{ 'titles.shipping' | cxTranslate }}
        </div>
        <hr />
        <app-shipping-details
          id="shippingDetails"
          [ngbCollapse]="isShippingCollapsed"
          [sippingAddress]="this.shippingAddress"
          [inkoTerm]="this.inkoTerm"
          [largestFilmLeadtime]="this.largestFilmLeadtime"
          [largestNonFilmLeadtime]="this.largestNonFilmLeadtime"
          [collectType]="this.collectList"
          [cartType]="this.cartType"
          [prePayAddType]="this.prePayAddList"
          [checkoutData]="this.checkoutData"
          (setModelData)="getShippingInput($event)"
          (setReqDate)="getReqDate($event)"
          (checkShippingAddressVal)="checkShippingAddressVal($event)"
        >
        </app-shipping-details>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isNotificationCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isNotificationCollapsed = !isNotificationCollapsed"
          [attr.aria-expanded]="!isNotificationCollapsed"
          aria-controls="notificationDetails"
        >
          {{ 'titles.notification' | cxTranslate }}
        </div>
        <hr />
        <app-notification-attachment
          id="notificationDetails"
          [ngbCollapse]="isNotificationCollapsed"
          [rmaInvoiceMail]="this.invoiceMail"
          [orderAckMail]="this.orderAckMail"
          [invoiceName]="this.invoiceName"
          [invoicePhone]="this.invoicePhone"
          [orderAckName]="this.orderAckName"
          [orderAckPhone]="this.orderAckPhone"
          (setNotificationData)="getNoitificationInput($event)"
          (checkNotificationVal)="checkNotificationVal($event)"
        >
        </app-notification-attachment>
      </div>
    </div>
    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isComplianceCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isComplianceCollapsed = !isComplianceCollapsed"
          [attr.aria-expanded]="!isComplianceCollapsed"
          aria-controls="complianceDetails"
        >
          {{ 'titles.compliance' | cxTranslate }}
        </div>
        <hr />
        <app-compliance-question
          id="complianceDetails"
          [ngbCollapse]="isComplianceCollapsed"
          (setComplianceData)="getComplianceInput($event)"
          [ccPaymentInfo]="this.ccPaymentInfoForSnapPay"
        >
        </app-compliance-question>
      </div>
    </div>

    <div class="section-container">
      <div class="section-container-buy">
        <div
          [ngClass]="
            isEndUserDetailsCollapsed
              ? 'accordion-toggle'
              : 'accordion-toggle-collapsed'
          "
          (click)="isEndUserDetailsCollapsed = !isEndUserDetailsCollapsed"
          [attr.aria-expanded]="!isEndUserDetailsCollapsed"
          aria-controls="endUserDetails"
        >
          {{ 'titles.endUserDetails' | cxTranslate }}
        </div>
        <hr />

        <app-enduser-details
          id="endUserDetails"
          [ngbCollapse]="isEndUserDetailsCollapsed"
          [endUserAddress]="this.endUserAddress"
          [cartType]="this.cartType"
          [customerAccount]="this.customerAccount"
          (checkEnduserAddress)="checkEnduserAddress($event)"
        >
        </app-enduser-details>
      </div>
    </div>
  </div>
  <div class="col-md-4 sticky-container">
    <div [ngClass]="{ sticky: isSticky }">
      <ds-checkout-order-summary
        (checkTermNCondition)="isChecked($event)"
      ></ds-checkout-order-summary>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="address-search-spinner cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
<div *ngIf="cardPayLoader" class="cx-dialog-body modal-body loader-popup">  
  <div>
    <cx-spinner></cx-spinner>   
  </div>
</div>