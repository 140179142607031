import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { Actions, ofType } from '@ngrx/effects';
import { LaunchDialogService } from '@spartacus/storefront';
import { saveAs } from 'file-saver';
import {
  GlobalMessageService,
  GlobalMessageType,
  TranslationService,
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { CartActions } from '@spartacus/cart/base/core';
import { CustomerAccountService } from '../../../../../core/customer-account/customer-account.service';
import { DS_DIALOG } from '../../../../../core/dialog/dialog.config';
import { AllProductLine } from '../../../../../shared/enums/availableProductList.enum';
import { SharedCartService } from '../../../../cart/cart-shared/shared-cart.service';
@Component({
  selector: 'app-waygate-cart-action',
  templateUrl: './waygate-cart-action.component.html',
  styleUrls: ['./waygate-cart-action.component.scss'],
})
export class WaygateCartActionComponent implements OnInit {
  userId;
  cartId$: Observable<string> = this.activeCartFacade.getActiveCartId();
  cartId: string;

  @Input()
  userType = 'current';

  @Input()
  cart$: Observable<any>;

  @Output()
  scrollToEntry: EventEmitter<any> = new EventEmitter();

  @Output()
  updateCart: EventEmitter<any> = new EventEmitter();

  @Output() isPartialShipping: EventEmitter<any> = new EventEmitter();

  cart;
  activeCartSubscription: Subscription;
  isPartialShipment: boolean = false;
  productLine: string;
  productLines = AllProductLine;

  constructor(
    private actions$: Actions,
    private cRef: ChangeDetectorRef,
    private multiCartFacade: MultiCartFacade,
    private activeCartFacade: ActiveCartFacade,
    private sharedCartService: SharedCartService,
    private launchDialogService: LaunchDialogService,
    private globalMessageService: GlobalMessageService,
    private custAccService: CustomerAccountService,
    private translate: TranslationService
  ) {}

  ngOnInit(): void {
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.productLine = productLine;
    });
    this.cart$.subscribe((res) => {
      this.cart = res;
      this.isPartialShipment = !this.cart.isShipCompleteOrder;
    });
    this.activeCartSubscription = this.cartId$.subscribe(
      (success) => {
        if (success) {
          this.cartId = success;
        }
      },
      (error) => {
        this.globalMessageService.add(
          error,
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        window.scrollTo(0, 0);
      }
    );

    this.sharedCartService.isShipmentPrefernce$.subscribe((shipmentPrefernce) => {
      if(shipmentPrefernce){
        this.isPartialShipment =shipmentPrefernce;
        this.cart.isShipCompleteOrder =shipmentPrefernce;
        this.cRef.detectChanges();
      }
  })
   this.sharedCartService.setCheckAvailabilityFlag([], true);
  }

  handleChange(event) {
    if (event.target.value == "'Partial'") {
      this.sharedCartService.updateisShipmentPrefernce(false);
      const Obj = {
        endCustomerNumber: null,
        guestConfirmEmail: null,
        guestEmail: null,
        isEndCustomerChanged: false,
        shipmentMethod: false,
      };
      this.isPartialShipment = true;
      this.sharedCartService
        .updateShipmentType(this.cartId, Obj)
        .subscribe((res) => {
          this.multiCartFacade.loadCart({
            cartId: this.cartId,
            userId: this.userType,
            extraData: {
              active: true,
            },
          });
          this.actions$
            .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
            .pipe(take(1))
            .subscribe(
              (r) => {
                this.updateCart.emit(true);
                this.cRef.detectChanges();
              },
              (error) => {
                this.globalMessageService.add(
                  error,
                  GlobalMessageType.MSG_TYPE_ERROR,
                  5000
                );
                window.scrollTo(0, 0);
              }
            );
        });
    } else {
      this.sharedCartService.updateisShipmentPrefernce(true);
      this.openShipmentDialog(event);
    }
  }
  openShipmentDialog(event) {
    const componentdata = {};

    const completeOrderDialog = this.launchDialogService.openDialog(
      DS_DIALOG.COMPLETE_ORDER,
      undefined,
      undefined,
      componentdata
    );
    if (completeOrderDialog) {
      completeOrderDialog.pipe(take(1)).subscribe((value) => {});
      this.launchDialogService.dialogClose.subscribe((value) => {
        if (value != undefined) {
          if (value == 'confirm-complete-order') {
            this.confirmShipment();
          } else if(value == 'close-complete-order') {
            const Obj = {
              endCustomerNumber: null,
              guestConfirmEmail: null,
              guestEmail: null,
              isEndCustomerChanged: false,
              shipmentMethod: false,
            };
            this.isPartialShipment = false;
            this.sharedCartService
              .updateShipmentType(this.cartId, Obj)
              .subscribe((res) => {
                this.multiCartFacade.loadCart({
                  cartId: this.cartId,
                  userId: this.userType,
                  extraData: {
                    active: true,
                  },
                });
                this.actions$
                  .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
                  .pipe(take(1))
                  .subscribe(
                    (r) => {
                      this.updateCart.emit(true);
                      this.cRef.detectChanges();
                    },
                    (error) => {
                      this.globalMessageService.add(
                        error,
                        GlobalMessageType.MSG_TYPE_ERROR,
                        5000
                      );
                      window.scrollTo(0, 0);
                    }
                  );
              });
           // window.location.reload();
            // event.target.value = "'Partial'"
            // this.handleChange(event)
          }
        }
        this.cRef.detectChanges();
      });
    }
  }
  confirmShipment() {
    const Obj = {
      endCustomerNumber: null,
      guestConfirmEmail: null,
      guestEmail: null,
      isEndCustomerChanged: false,
      shipmentMethod: true,
    };
    this.isPartialShipment = false;
    this.sharedCartService
      .updateShipmentType(this.cartId, Obj)
      .subscribe((res) => {
        this.multiCartFacade.loadCart({
          cartId: this.cartId,
          userId: this.userType,
          extraData: {
            active: true,
          },
        });
        this.actions$
          .pipe(ofType(CartActions.LOAD_CART_SUCCESS))
          .pipe(take(1))
          .subscribe(
            (r) => {
              this.updateCart.emit(true);
              this.isPartialShipping.emit(this.isPartialShipment);
              this.cRef.detectChanges();
            },
            (error) => {
              this.globalMessageService.add(
                error,
                GlobalMessageType.MSG_TYPE_ERROR,
                5000
              );
              window.scrollTo(0, 0);
            }
          );
      });
  }
  printPage() {
    window.print();
  }
  downloadCart() {
    this.sharedCartService.downloadCart(this.cartId).subscribe((res) => {
      let fileName = this.cartId;
      const blob = new Blob([res], { type: 'application/vnd.ms.excel' });
      const file = new File([blob], fileName + '.xls', {
        type: 'application/vnd.ms.excel',
      });
      saveAs(file);
    });
  }

  openSaveCartModal() {
    const componentData = {
      currentCart: this.cart,
    };
    const saveCartModalRef = this.launchDialogService.openDialog(
      DS_DIALOG.SAVE_CART_DIALOG,
      undefined,
      undefined,
      componentData
    );
    saveCartModalRef.pipe(take(1)).subscribe((value) => {});
  }

  ngOnDestroy(): void {
    if (this.activeCartSubscription) {
      this.activeCartSubscription.unsubscribe();
    }
  }
}
