<!-- <div #dialog> -->
<!-- Modal Header -->
<div class="cx-modal-container">
  <div class="cx-modal-content">
    <ng-container *ngIf="(loaded$ | async) || modalIsOpen; else loading">
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'addToCart.itemsAddedToYourCart' | cxTranslate }}
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="dismissModal('Cross click')"
        >
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div
        class="add-to-cart-dialog-body modal-body"
        *ngIf="entry$ | async as entry"
      >
        <div class="add-to-cart-product">
          <div *ngIf="entry" class="row entry-item-row">
            <div class="">
              <cx-media
                class="cx-product-image"
                [ngClass]="entry.product.images?.PRIMARY ? '' : 'is-missing'"
                [container]="entry.product.images?.PRIMARY"
                format="product"
                [alt]="entry.product.summary"
              ></cx-media>
            </div>

            <div class="product-desc-col">
              <div class="dialog-product-code">
                {{ entry.product.code }}
              </div>
              <a
                [title]="entry.product.name"
                [routerLink]="
                  { cxRoute: 'product', params: entry.product } | cxUrl
                "
                (click)="gtmSelectItemEvent(entry); dismissModal('Close on navigation')"
                class="dialog-product-name"
                [innerHTML]="entry.product.nameHtml"
              ></a>
            </div>
          </div>
        </div>
        <div class="cx-dialog-total" *ngIf="cart$ | async as cart">
          <div>
            {{
              'cartItems.cartTotal'
                | cxTranslate : { count: cart.deliveryItemsQuantity }
            }}
          </div>

          <ng-container *ngIf="user$ | async as user; else noPrice">
            <div>{{ cart.subTotal?.formattedValue }}</div>
          </ng-container>
          <ng-template #noPrice>
            <ng-container
              *ngIf="cart.commerceType == 'GUESTBUY'; else pricenot"
            >
              <div>{{ cart.subTotal?.formattedValue }}</div>
            </ng-container>
            <ng-template #pricenot>
              <div></div>
            </ng-template>
          </ng-template>
        </div>
        <!-- Total container -->
        <div class="cx-dialog-actions">
          <!-- Actions -->
          <div class="cx-dialog-buttons" *ngIf="cart$ | async as cart">
            <a
              [class.disabled]="form.dirty"
              [routerLink]="
                cart.commerceType == 'GUESTRFQ' ? 'quote/cart' : 'cart'
              "
              class="bh-button motion--normal bh-button--type-primary buttonMargin"
              autofocus
              (click)="!form.dirty && dismissModal('View Cart click')"
              >{{ 'addToCart.viewCart' | cxTranslate }}</a
            >
            <a
              *ngIf="
                cart.commerceType !== 'GUESTRFQ' &&
                cart.commerceType !== 'GUESTBUY' &&
                cart.cartType !== 'FILM' &&
                cart.cartType !== 'HYBRID'
              "
              [class.disable]="checkMinOrderQty(cart)"
              [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
              class="bh-button motion--normal bh-button--type-secondary"
              (click)="!form.dirty && dismissModal('Proceed To Checkout click')"
              >{{ 'addToCart.proceedToCheckout' | cxTranslate }}</a
            >
            <a
              *ngIf="cart.commerceType == 'GUESTRFQ'"
              [class.disabled]="form.dirty"
              class="bh-button motion--normal bh-button--type-secondary"
              (click)="!form.dirty && dismissModal('Proceed To Checkout click')"
              >{{ 'buyCart.countinueshopping' | cxTranslate }}</a
            >
            <a
              *ngIf="
                cart.commerceType == 'GUESTBUY' ||
                cart.cartType == 'FILM' ||
                cart.cartType == 'HYBRID'
              "
              [class.disable]="checkMinOrderQty(cart)"
              [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
              class="bh-button motion--normal bh-button--type-secondary"
              (click)="!form.dirty && dismissModal('Proceed To Checkout click')"
              >{{ 'addToCart.proceedToCheckout' | cxTranslate }}</a
            >
          </div>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ 'addToCart.updatingCart' | cxTranslate }}
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="dismissModal('Cross click')"
        >
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          <div class="col-sm-12"><cx-spinner></cx-spinner></div>
        </div>
      </div>
    </ng-template>
    <!-- </div> -->
  </div>
</div>
