import { Injectable } from "@angular/core";
import { StorageSyncType, WindowRef } from "@spartacus/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() {}

  public getStorage(storageType: StorageSyncType, winRef: WindowRef): Storage {
    let storage: Storage;

    switch (storageType) {
      case StorageSyncType.LOCAL_STORAGE: {
        storage = winRef.localStorage;
        break;
      }
      case StorageSyncType.SESSION_STORAGE: {
        storage = winRef.sessionStorage;
        break;
      }
      case StorageSyncType.NO_STORAGE: {
        storage = undefined;
        break;
      }

      default: {
        storage = winRef.sessionStorage;
      }
    }
    return storage;
  }

  public persistToStorage(
    configKey: string,
    value: any,
    storage: Storage
  ): void {
    if (value) {
      storage.setItem(configKey, JSON.stringify(value));
    }
  }

  public readFromStorage(storage: Storage, key: string): any {
    const storageValue = storage.getItem(key);
    if (!storageValue) {
      return;
    }
    return JSON.parse(storageValue);
  }

  public removeFromStorage(storage: Storage, key: string) {
    const storageValue = storage.getItem(key);
    if (!storageValue) {
      return;
    }
    storage.removeItem(key);
  }
}
