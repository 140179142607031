<div class="waygate-facet-container">
  <div class="facet-title" (click)="expand = !expand">
    <h4>{{ facet.name }}</h4>
    <div class="material-icons" *ngIf="expand">expand_less</div>
    <div class="material-icons" *ngIf="!expand">expand_more</div>
  </div>
  <div class="facet-body" *ngIf="expand">
    <ng-container
      *ngFor="
        let value of facet.values
          | facetSort
          | slice : 0 : showItems(facet.values)
      "
    >
      <a
        routerLink="./"
        [queryParams]="getLinkParams(value)"
        class="waygate-facet-link"
        [class.selected]="value?.selected"
        [class.disabled]="
          selectedOne && !value?.selected && facet?.priority > 9000
        "
      >
        {{ value.name }} ({{ value.count }})</a
      >
    </ng-container>
    <div
      *ngIf="show && facet.values.length > 5"
      (click)="show = !show"
      class="waygate-show-more"
    >
    {{ 'productList.showMore' | cxTranslate }}
    </div>
    <div
      *ngIf="!show && facet.values.length > 5"
      (click)="show = !show"
      class="waygate-show-more"
    >
    {{ 'productList.showLess' | cxTranslate }}
    </div>
  </div>
</div>

