import { FocusConfig } from '@spartacus/storefront';

export const DS_DIALOG = {
  IDLE_TIMEOUT: 'IDLE_TIMEOUT',
  ADD_TO_CART_DIALOG: 'ADD_TO_CART_DIALOG',
  CART_LOADING_DIALOG: 'CART_LOADING_DIALOG',
  SWITCH_CART_DIALOG: 'SWITCH_CART_DIALOG',
  RMA_SWITCH_CUSTOMER_DIALOG: 'RMA_SWITCH_CUSTOMER_DIALOG',
  CALI_DATA_SUCCESS_DIALOG: 'CALI_DATA_SUCCESS_DIALOG',
  CALI_DATA_REQUEST_DIALOG: 'CALI_DATA_REQUEST_DIALOG',
  SWITCH_CUSTOMER_DIALOG: 'SWITCH_CUSTOMER_DIALOG',
  ADDRESS_DIALOG: 'ADDRESS_DIALOG',
  CART_DELETE_DIALOG: 'CART_DELETE_DIALOG',
  SAVE_CART_DIALOG: 'SAVE_CART_DIALOG',
  RMA_SWITCH_CART_DIALOG: 'RMA_SWITHC_CART_DIALOG',
  GUEST_TRACK_DIALOG: 'GUEST_TRACK_DIALOG',
  VIEW_SALES_AREA_DIALOG: 'VIEW_SALES_AREA_DIALOG',
  DELETE_DIALOG: 'DELETE_DIALOG',
  SHARE_RMA_DIALOG: 'SHARE_RMA_DIALOG',
  GOTO_HAZARD_FORM_DIALOG: 'GOTO_HAZARD_FORM_DIALOG',
  ADDED_TO_RETURN_CART_DIALOG: 'ADDED_TO_RETURN_CART_DIALOG',
  READ_DISMISS_ALL_DIALOG: 'READ_DISMISS_ALL_DIALOG',
  DISMISS_ALL_DIALOG: 'DISMISS_ALL_DIALOG',
  PART_DELETE_DIALOG: 'PART_DELETE_DIALOG',
  SHARE_ORDER_DIALOG: 'SHARE_ORDER_DIALOG',
  GUEST_QUOTE_DIALOG: 'GUEST_QUOTE_DIALOG',
  RMA_CART_DELETE_DIALOG: 'RMA_CART_DELETE_DIALOG',
  REPEAT_RMA_UPDATE_MESSAGE_DIALOG: 'REPEAT_RMA_UPDATE_MESSAGE_DIALOG',
  SAVE_MODAL: 'SAVE_MODAL',
  ADD_ACCESSORIES_DIALOG: 'ADD_ACCESSORIES_DIALOG',
  WAYGATE_SEARCH: 'WAYGATE_SEARCH',
  REMOVE_PRODUCTS: 'REMOVE_PRODUCTS',
  QUICK_ORDER_ROUTE_GAURD: 'QUICK_ORDER_ROUTE_GAURD',
  WAYGATE_PDP_MODAL: 'WAYGATE_PDP_MODAL',
  WAYGATE_ADDRESS_DIALOG: 'WAYGATE_ADDRESS_DIALOG',
  CONFIGURATOR_OVERVIEW_MODAL:'CONFIGURATOR_OVERVIEW_MODAL',
  CSR_REQUEST_REJECT_ACTION: 'CSR_REQUEST_REJECT_ACTION',
  CSR_APPROVAL_CRITERIA_LIST: 'CSR_APPROVAL_CRITERIA_LIST',
  COMPLETE_ORDER: 'COMPLETE_ORDER',
  HAVE_A_QUESTION_DIALOG:'HAVE_A_QUESTION_DIALOG'
};

export const focusConfig: FocusConfig = {
  trap: true,
  block: true,
  autofocus: true,
  focusOnEscape: true,
};
