<div class="waygate-navigation-menu">
  <nav class="container" *ngIf="!isChooseBrandPage">
    <ul>
      <li class="home-only">
        <a [routerLink]="['/', productLine]">
          {{ 'navigation-menu.home' | cxTranslate }}
        </a>
      </li>
      <li #guestProductCategoryLink [class.selected]="showProdCat">
        <a class="prod-link" (click)="toggleProdCat()">
          {{ 'navigation-menu.products' | cxTranslate }}
          <!-- <div> -->
          <!-- <span class="material-icons down-arrow"> keyboard_arrow_up </span> -->
          <span class="material-icons" *ngIf="!showProdCat">
            keyboard_arrow_down
          </span>
          <span class="material-icons" *ngIf="showProdCat">
            keyboard_arrow_up
          </span>
          <!-- </div> -->
        </a>
      </li>
      <div class="cat-menu">
        <app-waygate-menu
          *ngIf="showProdCat"
          [clickSourceId]="guestProductCategoryLink"
          (clickOutside)="toggleProdCat()"
          (closeMenu)="toggleProdCat()"
          [legalEntities]="this.legalEntities"
          (toggleCategoryMenu)="toggleProdCat()"
        >
        </app-waygate-menu>
        <!-- <ds-category-menu
            *ngIf="showProdCat"
            [clickSourceId]="guestProductCategoryLink"
            (clickOutside)="toggleProdCat()"
            [legalEntities]="this.legalEntities"
            (toggleCategoryMenu)="toggleProdCat()"
          ></ds-category-menu> -->
      </div>
      <ng-container *ngIf="isServicesVisible">      
      <li #services [class.selected]="servicesOpened">
        <a class="prod-link" (click)="toggleService()">
          {{ 'navigation-menu.services' | cxTranslate }}
          <!-- <div> -->
          <!-- <span class="material-icons down-arrow"> keyboard_arrow_up </span> -->
          <span class="material-icons" *ngIf="!servicesOpened">
            keyboard_arrow_down
          </span>
          <span class="material-icons" *ngIf="servicesOpened">
            keyboard_arrow_up
          </span>
          <!-- </div> -->
        </a>
      </li>
      <div class="cat-menu">
        <app-waygate-menu-secondary
          *ngIf="servicesOpened"
          [clickSourceId]="services"
          (clickOutside)="toggleService()"
          (closeMenu)="toggleService()"
          [external]="true"
          [list]="servicesList"
        >
        </app-waygate-menu-secondary>
      </div>
    </ng-container>
      <ng-container *ngIf="isIndustriesVisible">
      <li #industries [class.selected]="industriesOpened" >
        <a class="prod-link" (click)="toggleIndustries()">
          {{ 'navigation-menu.industries' | cxTranslate }}
          <!-- <div> -->
          <!-- <span class="material-icons down-arrow"> keyboard_arrow_up </span> -->
          <span class="material-icons" *ngIf="!industriesOpened">
            keyboard_arrow_down
          </span>
          <span class="material-icons" *ngIf="industriesOpened">
            keyboard_arrow_up
          </span>
          <!-- </div> -->
        </a>
      </li>
      <div class="cat-menu" >
        <app-waygate-menu-secondary
          *ngIf="industriesOpened"
          [clickSourceId]="industries"
          (clickOutside)="toggleIndustries()"
          (closeMenu)="toggleIndustries()"
          [external]="true"
          [list]="industriesList"
        >
        </app-waygate-menu-secondary>
      </div>
    </ng-container>
      <ng-container *ngIf="user$ | async as user; else guestUser">
        <li #equipmentMenuLink [class.selected]="showEqMenu">
          <a class="prod-link" (click)="toggleEqMenu()">
            {{ 'navigation-menu.support' | cxTranslate }}
            <!-- <div> -->
            <!-- <span class="material-icons down-arrow"> keyboard_arrow_up </span> -->
            <span class="material-icons" *ngIf="!showEqMenu">
              keyboard_arrow_down
            </span>
            <span class="material-icons" *ngIf="showEqMenu">
              keyboard_arrow_up
            </span>
            <!-- </div> -->
          </a>
        </li>
        <div class="cat-menu">
          <app-waygate-menu-secondary
            *ngIf="showEqMenu"
            [clickSourceId]="equipmentMenuLink"
            (clickOutside)="toggleEqMenu()"
            (closeMenu)="toggleEqMenu()"
            [external]="false"
            [list]="supportList"
          >
          </app-waygate-menu-secondary>
        </div>
      </ng-container>
      <ng-template #guestUser>
        <li #equipmentMenuLink [class.selected]="showEqMenu">
          <a class="prod-link" (click)="toggleEqMenu()">
            {{ 'navigation-menu.support' | cxTranslate }}
            <!-- <div> -->
            <!-- <span class="material-icons down-arrow"> keyboard_arrow_up </span> -->
            <span class="material-icons" *ngIf="!showEqMenu">
              keyboard_arrow_down
            </span>
            <span class="material-icons" *ngIf="showEqMenu">
              keyboard_arrow_up
            </span>
            <!-- </div> -->
          </a>
        </li>
        <div class="cat-menu">
          <app-waygate-menu-secondary
            *ngIf="showEqMenu"
            [clickSourceId]="equipmentMenuLink"
            (clickOutside)="toggleEqMenu()"
            (closeMenu)="toggleEqMenu()"
            [external]="false"
            [list]="supportListGuest"
          >
          </app-waygate-menu-secondary>
        </div>
      </ng-template>
      <ng-container *ngIf="user$ | async as user">
        <li *ngIf="isQuickOrderDisplay">
          <a class="prod-link" (click)="quickOrderRedirect()">
            {{ 'navigation-menu.quickorder' | cxTranslate }}
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
  <div>
    <ds-waygate-customer-account-slider
      *ngIf="showSlider"
      [salesAreadata]="salesAreaData"
      [activeSalesArea]="activeSalesArea"
      (closeSlider)="showSlider = false"
    ></ds-waygate-customer-account-slider>
  </div>
</div>
