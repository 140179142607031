import { NgModule } from '@angular/core';
import { SegmentRefsRootModule } from "@spartacus/segment-refs/root";

@NgModule({
  declarations: [],
  imports: [
    SegmentRefsRootModule
  ]
})
export class SegmentRefsFeatureModule { }
