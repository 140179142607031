<div class="waygate-end-cust-address">
  <div>
    <div class="address-header">{{'address-model.endCustomerAddress' | cxTranslate}}</div>
    <div class="address-box">
      <div class="address-header-text">
        <div>{{ endUserAddress?.companyName }}</div>
        <!-- <div *ngIf="endUserAddress" class="address-header-content">
          Customer Account Number: {{ customerAccount }}
        </div> -->
        <div *ngIf="endUserAddress" class="address-header-content">
          Address: {{ endUserAddress?.line1 }}, {{ endUserAddress?.town }} ,
          {{ endUserAddress?.region?.name }},
          {{ endUserAddress?.country?.name }} {{ endUserAddress?.postalCode }}
        </div>
        <div class="no-address-text" *ngIf="!endUserAddress">
          {{'address-model.noAddressSelected' | cxTranslate}}
        </div>
        <div
          *ngIf="errorMsg"
          class="enduser-address err-msg-css"
        >
          <i class="typography--icon-small bh-form-message__icon err-msg-icon-css"
            >error_outline</i
          >{{ errorMsg }}
        </div>
      </div>

      <div class="address-header-btn">
        <button
          type="button"
          class="change-address-btn"
          aria-label="change"
          (click)="openAddressModel()"
        >
        {{ addressButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
