<div class="category-info-box row m-0 p-0">
  <div class="left-section col-md-9 col-lg-9">
    <div class="category-name mb-3">
      <span>{{ currentQuery.categoryName }}</span>
    </div>
    <div class="category-desc">
      <p [innerHTML]="currentQuery.categoryDescription"></p>
    </div>
  </div>
  <div class="right-section col-md-3 col-lg-3 p-0">
    <img
      class="category-img img-thumb"
      *ngIf="currentQuery.categoryPictureUrl"
      src="{{ apiurl + currentQuery.categoryPictureUrl}}"
    />
    <!-- <cx-media
      class="category-missing-img"
      *ngIf="!currentQuery.categoryPictureUrl"
      [container]="apiurl + currentQuery.categoryPictureUrl"
    >
    </cx-media> -->
  </div>
</div>
