<!-- <div #dialog> -->
<!-- Modal Header -->
<div class="cx-modal-container">
  <div class="cx-modal-content">
    <div class="cx-dialog-header modal-header bg-green">
      <div class="cx-dialog-title modal-title">Approval Criteria</div>
      <button
        type="button"
        aria-label="Close"
        class="close"
        (click)="closeModal()"
      >
        <i class="material-icons">close</i>
      </button>
    </div>
    <!-- Modal Body -->

    <div class="modal-body">
      <div class="row mb-2 flex-nowrap" *ngFor="let item of ruleList">
        <div class="icon ">
          <img
            *ngIf="item.ruleStatus == 'SUCCESS'; else rejectStatus"
            src="../../../../assets/img/access_granted.svg"
          />
        </div>
        <div class="title ml-3">
          {{ item.ruleMessage }}
        </div>
      </div>
    </div>
    <div class="modal-footer actions">
      <button
        type="button"
        class="btn btn-outline-light btn-cancel"
        (click)="closeModal()"
      >
        {{ 'dashboard.cancel' | cxTranslate }}
      </button>
    </div>
  </div>
</div>

<ng-template #rejectStatus>
  <img src="../../../../assets/img/rejected.svg" />
</ng-template>
