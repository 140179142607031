<div class="waygate-shipping-main mt-4">
  <form (ngSubmit)="customerForm.form.valid && isSubmitted && onSubmit()" #customerForm="ngForm" novalidate>
    <div class="shipping-sub">
      <div class="shipping-label">{{ 'titles.shipping' | cxTranslate }}</div>
      <!-- <div class="delivery-point">
        <bh-text-input
          class="delivery-point"
          type="text"
          small
          label="Delivery Point"
          placeholder="Delivery Point"
          maxlength="25"
          [value]="deliveryPoint"
          (keyup)="onChange($event, 'deliveryPoint')"
        >
        </bh-text-input>
      </div> -->
      <div>
        <label class="incoterms-label">{{'labels.incoTerms' | cxTranslate}}</label>
        <label class="ship-from-label">{{ inkoTerm }}</label>
      </div>
      <div class="shipdetails" *ngIf="this.showShipping">
        <p class="shipping-checkout shippingMethod">
          {{"labels.shippingMethod" | cxTranslate}}
        </p>
        <div class="shipping-mail" *ngIf="this.showShipping">
          <div class="radio-btn">
            <bh-radio-button *ngIf="this.showPrepay" label=' {{"labels.collect" | cxTranslate}}' value="collect" 
            [attr.checked]="this.deliveryOption ==='collect'"
              name="group1" (change)="handleChange($event)">
            </bh-radio-button>
            <bh-radio-button label=' {{"labels.partialAndAdd" | cxTranslate}}' value="prepayadd" name="group1"
            [attr.checked]="this.deliveryOption ==='prepay' || !this.showPrepay" (change)="handleChange($event)">
            </bh-radio-button>
          </div>
            <div class="vs-text-field" *ngIf="this.showPrepay">
            <bh-text-input
            *ngIf="showCarrierText"
              required
              type="text"
              small
              maxlength="35"
              label='{{"labels.shippingAccountNumber" | cxTranslate}}'
              placeholder='{{"labels.shippingAccountNumber" | cxTranslate}}'
              [value]="collect"
              [message]="error.shipAccountNo"
              [attr.error]="error.shipAccountNo?error.shipAccountNo:null"
              (keyup)="onChange($event,'collectText')"
            >
            </bh-text-input>
            </div>
          
          <div class="shipdetails" *ngIf="this.showPrepay">
            <!-- <div>
              <div>
                <bh-dropdown class="ship-contact-dropdown" menuWidth="fluid" unselectable="false" ngbTooltip="{{carrier}}" [menuItems]="props"
                  unselectable="false" label='{{"labels.carrier" | cxTranslate}} ' required="true"
                  placeholder='{{"labels.select" | cxTranslate}} ' [value]="carrier" isSmall="true"
                  (select)="getCarrier($event)">
                </bh-dropdown>
              </div>
              <div *ngIf="this.error.shipCarrier"
                class="bh-form-message typography--body-small bh-form-message--error col-lg-12 col-md-12 col-sm-12">
                <i class="typography--icon-small bh-form-message__icon">error_outline</i>{{this.error.shipCarrier}}
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="ship-contact-main">
        <div>
          <bh-text-input type="text" required small label="Ship Contact Name" placeholder="Enter name"
            [value]="ShipName" [message]="error.ShipName" [attr.error]="error.ShipName ? error.ShipName : null"
            (keyup)="onChange($event, 'ShipName')" maxlength="35">
          </bh-text-input>
        </div>
        <div>
          <bh-text-input type="text" required small label="Ship Contact Phone Number" placeholder="Enter phone number"
            [value]="shipContact" [message]="error.shipContact"
            [attr.error]="error.shipContact ? error.shipContact : null" (keyup)="onChange($event, 'shipContact')"
            maxlength="16">
          </bh-text-input>
        </div>
      </div>
      <div>
        <bh-text-input type="text" required small label="Ship Notification Email" placeholder="Enter email address"
          [value]="shipNotimail" [message]="error.shipNotimail"
          [attr.error]="error.shipNotimail ? error.shipNotimail : null" (keyup)="onChange($event, 'shipNotimail')"
          maxlength="70">
        </bh-text-input>
      </div>
    </div>
    <div class="shipping-remarks mt-2">
      <label class="text-icon-label"><span class="star"></span> {{"labels.shippingRemarks" | cxTranslate}}
      </label>
      <!-- <bh-text-area
        class="shipping-remarks-text"
        label="Shipping Remarks"
        name="reasonForCsrReview"
        messageType="count"
        messageText=""
        maxcharacters="500"
        value="Write something..."
        [(ngModel)]="shippingRemark"
      ></bh-text-area> -->
      <textarea class="shipping-remarks-text" name="reasonForCsrReview" [(ngModel)]="shippingRemark"
        maxlength="500"></textarea>
    </div>
  </form>
</div>