import { NgModule } from '@angular/core';
import { ProductMultiDimensionalListRootModule } from "@spartacus/product-multi-dimensional/list/root";

@NgModule({
  declarations: [],
  imports: [
    ProductMultiDimensionalListRootModule
  ]
})
export class ProductMultiDimensionalListFeatureModule { }
