import { NgModule } from '@angular/core';
import { OppsRootModule } from "@spartacus/opps/root";

@NgModule({
  declarations: [],
  imports: [
    OppsRootModule
  ]
})
export class OppsFeatureModule { }
