<ng-container *ngIf="isCartEmpty$ | async">
  <p
    *ngIf="data$ | async as data"
    [innerHTML]="data.content"
    class="cx-empty-cart-info"
  ></p>
</ng-container>

<ng-container *ngIf="saveForLater$ | async as saveForLater">
  <ng-container *ngIf="entries$ | async as entries">
    <div *ngIf="saveForLater.totalItems > 0" class="cart-details-wrapper">
      <div class="cx-total">
        {{ 'saveForLaterItems.itemTotal' | cxTranslate: { count:
        saveForLater.totalItems } }}
      </div>
      <ds-cart-item-list
        [items]="entries"
        [readonly]="false"
        [cartIsLoading]="!(cartLoaded$ | async)"
        [options]="{
          isSaveForLater: true,
          optionalBtn: moveToCartBtn
        }"
      ></ds-cart-item-list>
    </div>
  </ng-container>
</ng-container>

<ng-template let-ctx #moveToCartBtn>
  <div class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn">
    <button
      class="link cx-action-link"
      [disabled]="ctx.loading"
      (click)="moveToCart(ctx.item)"
      type="button"
    >
      {{ 'saveForLaterItems.moveToCart' | cxTranslate }}
    </button>
  </div>
</ng-template>
