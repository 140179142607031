<div class="vs-quantity-counter">
    <button class="substract-btn vs-tertiary-btn" type="button" (click)="decrement()" [disabled]="control?.disabled || control?.value <= min || disabled" tabindex="-1">
    <i class='material-icons'>remove</i>
  </button>

    <input #qty class="qty-input" min="1" max="9999" [step]="step" [readonly]="readonly" [formControl]="control" (blur)="onInputBlur()" [attr.disabled]="disabled ? true : null" />

    <button type="button" class="add-btn vs-tertiary-btn" (click)="increment()" [disabled]="control?.disabled || control?.value >= max || disabled" tabindex="-1">
    <i class='material-icons'>add</i>
  </button>
</div>