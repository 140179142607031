<div class="waygate-search-backdrop">
  <div class="waygate-search-close">
    <span class="material-icons" (click)="closeDialog('close')"> close </span>
  </div>
  <div class="waygate-search-container">
    <div class="waygate-search-dialog-input-container">
      <input
        #searchInputWaygate
        type="text"
        class="waygate-search-dialog-input"
        placeholder="Search by product name / part number"
        tabindex="0"
        (input)="search($event)"
        (keydown.enter)="closeDialog('goToSearch'); goToPage()"
        autofocus
      /><span class="material-icons waygate-search-dialog-icon"> search </span>
    </div>
  </div>
  <div class="waygate-search-results-container">
    <ng-container *ngIf="results$ | async as result; else loading">
      <ng-container
        *ngIf="
          result?.products?.length > 0 && searchQuery?.length > 2;
          else noProduct
        "
      >
        <div class="message">
          {{
            'waygate.searchResult.countSearchResult'
              | cxTranslate : { count: result.products.length }
          }}
        </div>
        <div class="waygate-search-results-box">
          <ng-container *ngFor="let product of result.products">
            <a
              [routerLink]="[
                '/',
                productLine,
                'product',
                product.code,
                product.name
              ]"
              (click)="closeDialog('go to product')"
            >
              <div class="search-product-image-container">
                <cx-media
                  class="cx-product-image waygate-product-image"
                  [container]="product.images?.PRIMARY"
                  format="product"
                  [alt]="product?.summary"
                ></cx-media>
              </div>
              <div class="search-product-content-container">
                <div class="search-product-code">{{ product.code }}</div>

                <div
                  class="search-product-name"
                  [innerHTML]="product?.name"
                ></div>

                <div class="product-tag">
                  {{ 'waygate.searchResult.productHeader' | cxTranslate }}
                </div>
              </div>
            </a>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #noProduct>
  <ng-container *ngIf="results$ | async as result">
    <div
      class="no-results-found"
      *ngIf="searchQuery?.length > 2 && result?.products?.length == 0"
    >
      <img
        src="../../../../assets/img/empty-box.svg"
        alt="{{ 'waygate.searchResult.noResultsfound' | cxTranslate }}"
      />
      <h5>{{ 'waygate.searchResult.noResultsfound' | cxTranslate }}</h5>
      <p>{{ 'waygate.searchResult.tryWithDifferent' | cxTranslate }}</p>
    </div>
  </ng-container>
</ng-template>
<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
