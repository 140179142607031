import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductCategoriesState } from "../reducers/product-categories.reducer";

export const selectProductCategoriesState = createFeatureSelector<
  ProductCategoriesState
>("product-categories");

export const fetchProductCategories = createSelector(
    selectProductCategoriesState,
  (state) => state.productCategories
);
