import { NgModule } from '@angular/core';
import { OmfRootModule } from "@spartacus/omf/root";

@NgModule({
  declarations: [],
  imports: [
    OmfRootModule
  ]
})
export class OmfFeatureModule { }
