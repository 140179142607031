import { ScrollContainerComponent } from './scroll-container.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';



@NgModule({
  declarations: [ScrollContainerComponent],
  imports: [
    CommonModule    
  ],
  exports: [ScrollContainerComponent],
})
export class ScrollContainerModule { }