import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { ActiveCartFacade, OrderEntry } from '@spartacus/cart/base/root';
import {
  AuthService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  OccEndpointsService,
  TranslationService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { ItemListTypeEnum, GtmEvents } from './../../../shared/enums/gtm.enum';
import { Product } from '../../../core/product-catalog/model/product-model';
import {
  AnonymousActions,
  BuyActions,
  ReturnActions,
} from '../../../core/product-catalog/model/product-catelog.model';
import { CustomerAccountService } from '../../../core/customer-account/customer-account.service';
import { DS_DIALOG } from '../../../core/dialog/dialog.config';
import { ApiService } from '../../../core/http/api.service';
import { ProductCatelogService } from '../../../core/product-catalog/services/product-catelog.service';
import { ProductReturnService } from '../../../core/product-catalog/services/product-return.service';
import { CommerceTypes } from '../../../shared/models/commerceTypes.model';
import {
  EcommerceItem,
  Ecommerce,
  GTMDataLayer,
} from '../../../shared/models/googleTagManager.model';
import { GoogleTagManagerService } from '../../../shared/services/gtm.service';

@Component({
  selector: 'app-waygate-add-to-cart',
  templateUrl: './waygate-add-to-cart.component.html',
  styleUrls: ['./waygate-add-to-cart.component.scss'],
})
export class WaygateAddToCartComponent implements OnInit {
  maxQuantity: number = 9999;
  quantity: number = 1;
  @Input() product: Product;
  @Input() productCode: string;
  @Input() currentBuyAction: string;
  @Input() currentReturnAction: string;
  @Input() leadTime: number;
  @Input() inStock: boolean;
  @Input() currentGuestAction;

  @Output() isProductNotAddedToCart = new EventEmitter();
  
  minDate = new Date();
  currentUserType;
  count: number = 1;
  buyActions = BuyActions;
  userLoggedIn$: Observable<boolean>;
  increment: boolean = false;
  returnActions = ReturnActions;
  guestActions = AnonymousActions;
  cartEntry$: Observable<OrderEntry>;
  subscription: Subscription;
  isProductUpdating: boolean = false;
  disableCheckAvailablity: boolean = true;
  addToCartForm: FormGroup;
  estShipData: any[];
  contactUsUrl: string;
  constructor(
    private launchDialogService: LaunchDialogService,
    protected occEndpoints: OccEndpointsService,
    private apiService: ApiService,
    private authService: AuthService,
    private customerAccService: CustomerAccountService,
    protected activeCartFacade: ActiveCartFacade,
    private productCatService: ProductCatelogService,
    private actions$: Actions,
    private returnProdService: ProductReturnService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private gtmService: GoogleTagManagerService,
    private translate: TranslationService,
    private custAccService: CustomerAccountService
  ) {}

  ngOnInit(): void {
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.addToCartForm = new FormGroup({
      quantity: new FormControl(1),
    });
    this.userLoggedIn$.subscribe((res) => {
      if (res) this.currentUserType = OCC_USER_ID_CURRENT;
      else this.currentUserType = OCC_USER_ID_ANONYMOUS;
    });
    this.disableCheckAvailablity = this.currentBuyAction == BuyActions.BUY && this.currentReturnAction == ReturnActions.RETURN ? true : false;
    this.custAccService.getProductLine().subscribe((productLine) => {
      this.contactUsUrl = `/${productLine}/contactus`;
    });
  }

  ngOnChanges(): void {
    this.userLoggedIn$ = this.authService.isUserLoggedIn();
    this.addToCartForm = new FormGroup({
      quantity: new FormControl(1),
    });
    this.userLoggedIn$.subscribe((res) => {
      if (res) this.currentUserType = OCC_USER_ID_CURRENT;
      else this.currentUserType = OCC_USER_ID_ANONYMOUS;
    });
    this.disableCheckAvailablity = this.currentBuyAction == BuyActions.BUY && this.currentReturnAction == ReturnActions.RETURN ? true : false;
  }

  public openModal() {
    const componentdata = {
      entry$: this.activeCartFacade.getLastEntry(this.productCode),
      cart$: this.activeCartFacade.getActive(),
      loaded$: this.activeCartFacade.isStable(),
      quantity: this.quantity,
      increment: this.increment,
    };
    const addToCartDialog = this.launchDialogService.openDialog(
      DS_DIALOG.WAYGATE_PDP_MODAL,
      undefined,
      undefined,
      componentdata
    );
    if (addToCartDialog) {
      addToCartDialog.pipe(take(1)).subscribe((value) => {});
    }
    this.activeCartFacade.getActive().subscribe((data: any) => {
      localStorage.setItem('numberOfCart', data.totalUnitCount);
    });
  }

  increaseQuantity(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }

  decreaseQuantity(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }
  quantityAdded(count: any) {
    this.quantity = count;
    this.disableCheckAvailablity = false;
  }

  updateAvailablity() {
    this.isProductUpdating = true;
    const qty = this.addToCartForm.get('quantity').value;
    if (this.product !== undefined) {
      const defaultPlant = this.product?.plantAvailableAt.find(
        (plant) => plant.defaultPlant
      );
      const plantId = defaultPlant.code;
      const url = this.getEndpoint(this.product.code, 'details', plantId, qty);
      this.apiService.getData(url).subscribe((res) => {
        this.isProductUpdating = false;
        this.product = res;
        this.disableCheckAvailablity = true;
        this.cd.detectChanges();
        const defaultPlant = this.product?.plantAvailableAt.find(
          (plant) => plant.defaultPlant
        );
        if (defaultPlant) {
          this.inStock = defaultPlant.stockAvailable >= qty;
        }
        this.estShipData = this.product?.estShipData;
      });
    }
  }

  addToCart(isAddToQuote?: boolean) {
    let currentCartType: CommerceTypes;
    let userType;
    let lastSalesArea;
    this.userLoggedIn$.subscribe((res) => {
      if (res) {
        currentCartType = CommerceTypes.BUY;
        userType = OCC_USER_ID_CURRENT;
      } else if (isAddToQuote) {
        currentCartType = CommerceTypes.GUESTQUOTE;
        userType = OCC_USER_ID_ANONYMOUS;
        lastSalesArea = JSON.parse(sessionStorage.getItem('lastSalesArea'));
      } else {
        currentCartType = CommerceTypes.GUESTBUY;
        userType = OCC_USER_ID_ANONYMOUS;
        lastSalesArea = JSON.parse(sessionStorage.getItem('lastSalesArea'));
      }
    });

    let currentSalesArea =
      this.customerAccService.getGuestActiveSalesAreaFromStorage();
    this.activeCartFacade
      .getActive()
      .pipe(
        take(1),
        concatMap((activeCart: any) => {
          let cartId;
          if (userType === OCC_USER_ID_CURRENT) {
            cartId = activeCart.code;
          } else if (userType === OCC_USER_ID_ANONYMOUS) {
            cartId = activeCart.guid;
          }
          if (activeCart.entries?.length > 0) {
            if (activeCart.commerceType != currentCartType) {
              this.openSwitchCartModal(
                activeCart.commerceType,
                currentCartType,
                cartId
              );
            } else if (
              lastSalesArea &&
              lastSalesArea.salesAreaId != currentSalesArea.salesAreaId
            ) {
              this.openSwitchCartModal(
                activeCart.commerceType,
                currentCartType,
                cartId,
                true
              );
            } else {
              this.addProductToBuyCart();
            }
            return of({ modal: true });
          } else {
            return this.productCatService.saveCartType(
              cartId,
              currentCartType,
              userType
            );
          }
        })
      )
      .subscribe(
        (val) => {
          if (val === null) {
            this.addProductToBuyCart();
          }
        },
        (error) => {}
      );
  }

  addProductToBuyCart() {
    const quantity = this.addToCartForm.get('quantity').value;
    if (!this.productCode || quantity <= 0) {
      return;
    }
    this.activeCartFacade
      .getEntry(this.productCode)
      .pipe(take(1))
      .subscribe((prevEntry) => {
        if (prevEntry) {
          this.activeCartFacade.updateEntry(
            prevEntry?.entryNumber,
            prevEntry?.quantity + quantity
          );
          this.actions$
            .pipe(ofType(CartActions.CART_UPDATE_ENTRY_SUCCESS), take(1))
            .subscribe((cartResponse: any) => {
              this.pushAddToCartEvent(cartResponse);
              this.openModal();
            });
        } else {
          this.activeCartFacade
            .getEntry(this.productCode)
            .subscribe((entry) => {
              if (entry) {
                this.increment = true;
              }
              this.userLoggedIn$.subscribe((res) => {
                if (!res)
                  sessionStorage.setItem(
                    'lastSalesArea',
                    JSON.stringify(
                      this.customerAccService.getGuestActiveSalesAreaFromStorage()
                    )
                  );
              });
              this.activeCartFacade.addEntry(this.productCode, quantity);
              this.increment = false;
              this.actions$
                .pipe(ofType(CartActions.CART_ADD_ENTRY_SUCCESS), take(1))
                .subscribe((cartResponse: any) => {
                  this.actions$
                .pipe(ofType(CartActions.LOAD_CART_SUCCESS), take(1))
                .subscribe((loadResponse: any) => {                  
                  if(!loadResponse?.payload?.cart?.deletedProductCodes){
                    this.isProductNotAddedToCart.emit(false);
                    this.pushAddToCartEvent(cartResponse);
                    this.openModal();
                  }
                  else{
                    this.isProductNotAddedToCart.emit(true);
                  }
                })
                
                 
                });
            })
            .unsubscribe();
        }
      });
  }

  private openSwitchCartModal(
    currentCartType,
    switchToCartType,
    cartId,
    switchSame?: boolean
  ) {
    const componentdata = {
      currentCartType: currentCartType,
      switchToCartType: switchToCartType,
      currentCartCode: cartId,
      switchSame: switchSame,
    };
    const switchCartModel = this.launchDialogService.openDialog(
      DS_DIALOG.SWITCH_CART_DIALOG,
      undefined,
      undefined,
      componentdata
    );
    if (switchCartModel) {
      switchCartModel.pipe(take(1)).subscribe((value) => {
        if (value == true || value?.instance?.reason == true) {
          this.addProductToBuyCart();
        }
      });
    }
  }

  returnProduct() {
    const updatedProduct = {
      ...this.product,
      similar: false,
    };
    this.returnProdService.selectRmaProduct(updatedProduct);
    this.router.navigate(['/rma-form']);
  }

  protected getEndpoint(
    code: string,
    scope?: string,
    plantId?: string,
    qty?: number
  ): string {
    return this.occEndpoints.buildUrl('product', {
      urlParams: {
        userId: this.currentUserType,
      },
      queryParams: {
        productCode: code,
        defaultPlant: plantId,
        quantity: qty,
      },
      scope,
    });
  }
  getShipDate(dt) {
    if (
      dt.shipDate == '01-Jan-2100' ||
      dt.shipDate.toLowerCase() == 'no estimate available'
    ) {
      return '';
    }
    return moment(dt.shipDate).format('D MMMM, YYYY');
  }

  getTranslatedText(key) {
    let message;
    this.translate.translate(key).subscribe((res) => {
      message = res;
    });
    return message;
  }

  getInt(product) {
    if (!!product) {
      const defaultPlant = this.product?.plantAvailableAt?.find(
        (plant) => plant.defaultPlant
      );
      if (defaultPlant) {
        return parseInt(defaultPlant.stockAvailable);
      }
    }
  }

  requestQuote() {
    this.addToCart(true);
  }

  checkStockAvailablity(product) {
    const qty = this.addToCartForm.get('quantity').value;
    let stockAvailable = false;
    if (!!product) {
      const defaultPlant = this.product?.plantAvailableAt?.find(
        (plant) => plant.defaultPlant
      );
      if (defaultPlant) {
        stockAvailable = defaultPlant.stockAvailable >= qty;
      }
    }
    return stockAvailable;
  }

  pushAddToCartEvent(cartResponse) {
    const item: EcommerceItem = {
      price: this.product?.yourPrice?.value,
      quantity: this.addToCartForm.get('quantity').value,
      item_id: this.product?.code,
      item_name: this.product?.name,
      discount: this.product?.discountPercentage
        ? Number(this.product?.discountPercentage)
        : '',
      item_brand: this.gtmService.getItemBrand(),
      item_list_id: ItemListTypeEnum.ProductDetail,
      item_list_name: ItemListTypeEnum.ProductDetail,
      item_category:
        this.product?.breadCrumbs && this.product?.breadCrumbs.length
          ? this.product?.breadCrumbs[0]?.name
          : '',
      item_category2:
        this.product?.breadCrumbs && this.product?.breadCrumbs.length
          ? this.product?.breadCrumbs[1]?.name
          : '',
      item_category3:
        this.product?.breadCrumbs && this.product?.breadCrumbs.length
          ? this.product?.breadCrumbs[2]?.name
          : '',
      item_category4:
        this.product?.breadCrumbs && this.product?.breadCrumbs.length
          ? this.product?.breadCrumbs[3]?.name
          : '',
      item_category5:
        this.product?.breadCrumbs && this.product?.breadCrumbs.length
          ? this.product?.breadCrumbs[4]?.name
          : '',
      item_variant: '',
      index: 0,
    };

    const eventData: Ecommerce = {
      currency: this.product?.yourPrice?.currencyIso || '',
      value:
        this.product?.yourPrice?.value *
          this.addToCartForm.get('quantity').value || '',
      items: [item],
    };
    const event: GTMDataLayer = {
      event: GtmEvents.AddToCart,
      store: this.gtmService.getItemBrand(),
      ecommerce: eventData,
    };
    this.gtmService.sendEvent(event);
  }
}
