export class ContactUsFormModel {
  firstName: string;
  lastName: string;
  companyName: string;
  jobRole: string;
  companyEmail: string;
  phoneNumber: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  areaOfIntrest: string;
  requestDetails: string;
  communicationPef: string;
}