<div class="row custom-last-child-margin pageBtnMargin">
  <div class="share-detail">
    <div class="right">
      <span class="shareDetail" (click)="shareDetail()">{{
        'rma-tracking.share' | cxTranslate
      }}</span>
      <span class="backToList" (click)="backLink()">{{
        'rma-tracking.back' | cxTranslate
      }}</span>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div
      class="
        tabcontent-display-line-section-heading
        title-text title-padding-bottom
      "
    >
      <em class="material-icons custom-margin-right-icon">description</em>
      {{ 'rma-tracking.associateDocument' | cxTranslate }}
      <div class="heading-extras row">
        <span class="info-item cust-info-header"
          >{{ 'rma-tracking.poHeader' | cxTranslate }}:<strong
            class="cust-info-content"
          >
            {{ rmaTrackingResponse?.purchaseOrderNumber }}</strong
          >
        </span>
        <span class="info-item cust-info-header"
          >{{ 'rma-tracking.poDateHeader' | cxTranslate }}:
          <strong
            *ngIf="rmaTrackingResponse?.purchaseOrderDate == '0000-00-00'"
          >
          </strong>
          <strong
            class="cust-info-content"
            *ngIf="rmaTrackingResponse?.purchaseOrderDate != '0000-00-00'"
          >
            {{
              rmaTrackingResponse?.purchaseOrderDate | date: 'd MMM,y'
            }}</strong
          >
        </span>
        <span class="info-item cust-info-header">
          {{ 'rma-tracking.orderStatusHeader' | cxTranslate }} :
          <span
            class="status-label in-progress"
            [ngStyle]="{
              'background-color': fetchOrderStatusColor(
                rmaTrackingResponse?.rmaStatus
              )
            }"
          >
            {{ rmaTrackingResponse?.rmaStatus }}</span
          >
          <div
            *ngIf="rmaTrackingResponse?.rmaStatus == 'RMA SUBMITTED'"
            class="text-danger"
            matTooltipClass="vs-tooltip capitalize"
            matTooltip="This RMA has some unusual situation.  We will be in contact with you to help move this ahead."
            matTooltipPosition="below"
          >
            <strong>{{ 'rma-tracking.pending' | cxTranslate }}</strong> :
            {{ 'rma-tracking.doNotShip' | cxTranslate }}
          </div>
        </span>
      </div>
      <!-- <span
        class="download-all-txt"
        *ngIf="viewAllDocuments?.length > 0"
        [ngClass]="downloadAllLoader ? 'disabled' : ''"
        (click)="downloadAllFiles()"
        ><em class="icons download"></em
        >{{ 'rma-tracking.downloadAll' | cxTranslate }}</span
      > -->
      <div
        *ngIf="downloadAllLoader"
        class="title product-search-spinner cx-spinner"
      >
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </div>

  <div
    class="col-lg-12 col-md-12 col-sm-12 mt-4 blocked-warning-message"
    *ngIf="fullDocumentsResponse?.length === 0"
  >
    <span class="obsolete-text">
      <em class="material-icons">info</em>
      <strong>&nbsp;{{ 'rma-tracking.information' | cxTranslate }}</strong
      >&nbsp; {{ 'rma-tracking.noDocAvailable' | cxTranslate }}</span
    >
  </div>
  <div *ngIf="fullDocumentsResponse?.length > 0" style="width: 100%">
    <div class="attacment-count">
      {{ fullDocumentsResponse?.length }}
      {{ 'rma-tracking.attachedDocuments' | cxTranslate }}
    </div>
    <div class="before-attachment">
      <div class="custom-control custom-checkbox">
        <input
          id="master"
          type="checkbox"
          class="custom-control-input"
          [indeterminate]="client.indeterminated"
          [checked]="client.checked"
          (click)="onMasterClick(client, $event)"
        />
        <label for="master" class="custom-control-label">{{
          'rma-tracking.selectAll' | cxTranslate
        }}</label>
      </div>
      <div
        (click)="downloadSelectedAttachment()"
        class="downloadSelectedAttachment"
      >
        <span class="material-icons"> sim_card_download </span>
      </div>
    </div>
    <div
      class="col-lg-12 col-md-12 col-sm-12 mt-2"
      *ngFor="let documentItem of fullDocumentsResponse; let i = index"
    >
      <div class="upload-block">
        <bh-checkbox
          [checked]="checkAll"
          value="true"
          [name]="'documentItem.fileName' + i"
          (change)="checkEntry($event, documentItem)"
        ></bh-checkbox>
        <span
          class="material-icons vertical-middle"
          [ngClass]="downloadLoader[i] ? 'disabled' : ''"
          >insert_drive_file</span
        >
        <a [ngClass]="downloadLoader[i] ? 'disabled' : ''">{{
          documentItem.fileName
        }}</a>
        <div class="customeBtn" *ngIf="!downloadLoader[i]">
          <bh-button
            [type]="'tertiary'"
            [label]="'rma-tracking.download' | cxTranslate"
            (click)="
              downloadDocument(documentItem.fileName, documentItem.fileType, i)
            "
          ></bh-button>
        </div>
        <div
          *ngIf="downloadLoader[i]"
          class="product-search-spinner cx-spinner"
        >
          <bh-button
            [type]="'tertiary'"
            [label]="'rma-tracking.downloading' | cxTranslate"
          ></bh-button>
        </div>
      </div>
    </div>
  </div>
</div>
